import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { Layout } from '../components/Layout/Layout';
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Anchor,
  Breadcrumb,
} from 'antd';
import { lessonContentType } from '../types/index';
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { ROUTES } from '../constants/routes';
import './lessonPage.less';

const { Link: AnchorLink } = Anchor;
type LessonPageProps = {
  strapiLesson: {
    lessonTest: {
      questions: [];
    };
    strapi_id: number;
    name: string;
    id: string;
    description: string;
    lessonContent: lessonContentType;
    lessonNumber: number;
    course: {
      slug: string;
      name: string;
    };
  };
};

export default function LessonPage({
  data: { strapiLesson },
}: PageProps<LessonPageProps>) {
  const { Title, Text, Paragraph } = Typography;
  const getDurations = () => {
    const hoursStartIndex = 1;
    const hoursEndIndex = 2;
    const minutesStartIndex = 3;
    const minutesEndIndex = 5;
    const minutes = strapiLesson?.lessonContent?.time.slice(
      minutesStartIndex,
      minutesEndIndex
    );
    const hours = strapiLesson?.lessonContent?.time.slice(
      hoursStartIndex,
      hoursEndIndex
    );

    if (parseInt(hours) !== 0) {
      return `${hours}h ${minutes} min`;
    }
    return `${minutes} min`;
  };
  const testsAmount = strapiLesson?.lessonTest?.questions.length || 0;
  return (
    <Layout>
      <section className="lesson-page-section">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href="/">Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={`${ROUTES.COURSE}/`}>Kurs</Breadcrumb.Item>
          <Breadcrumb.Item
            href={`${ROUTES.COURSE}/${strapiLesson.course.slug}`}
          >
            {strapiLesson.course.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`lekcja${strapiLesson.lessonNumber}`}>
            {`Lekcja ${strapiLesson.lessonNumber}`}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <Row justify="space-between">
          <Col span={24}>
            <Title className="title-container" id="1" level={2}>
              {strapiLesson?.name}
            </Title>
            <Paragraph>{strapiLesson?.description}</Paragraph>
            <Title level={3} className="header-blue header-video">
              Lekcja video
            </Title>
            <div>
              <ClockCircleOutlined className="time-icon" />
              <Text className="lesson-time-text">{getDurations()}</Text>
            </div>
            <div className="video-container">
              <video
                src={strapiLesson?.lessonContent?.video?.localFile?.url}
                controls
              />
            </div>
            <Title className="header-blue" id="3" level={3}>
              Podsumowanie:
            </Title>
            <Paragraph>
              {strapiLesson?.lessonContent?.summary?.data?.summary}
            </Paragraph>

            <div className="lesson-footer">
              <section className="test-container">
                <Title className="header-blue" id="4" level={3}>
                  Sprawdź swoją wiedzę
                </Title>
                <Text className="test-description">
                  Zobacz, czego udało Ci się nauczyć. Czeka na Ciebie test
                  wielokrotnego wyboru złożony z {testsAmount} pytań.
                </Text>
                <Row className="check-your-knowledge-button">
                  <Button type="default">
                    <Link to="test">
                      <Text>Przejdź do testu</Text>
                    </Link>
                  </Button>
                </Row>
              </section>
              <section className="attachments-container">
                <Text className="attachments-title">Materiały do pobrania</Text>
                {strapiLesson?.lessonContent?.attachments?.map(
                  (attachment, index) => {
                    return (
                      <Link
                        to={attachment.localFile.url}
                        key={index}
                        target="_blank"
                        download
                      >
                        <Button className="ant-btn-primary attachments-button">
                          <DownloadOutlined className="attachment-download-icon" />
                          <Text className="attachment-text">Pobierz (PDF)</Text>
                        </Button>
                      </Link>
                    );
                  }
                )}
              </section>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}
export const query = graphql`
  query ($strapi_id: Int) {
    strapiLesson(strapi_id: { eq: $strapi_id }) {
      strapi_id
      course {
        slug
        name
      }
      lessonNumber
      id
      name
      description
      lessonContent {
        video {
          localFile {
            url
          }
        }
        attachments {
          localFile {
            url
          }
        }
        time
        summary {
          data {
            summary
          }
        }
        # LessonVideo {
        #   playback_id
        # }
      }
      lessonTest {
        questions {
          strapi_id
        }
      }
    }
  }
`;
